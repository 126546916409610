<template>
    <span class="font-semibold text-white cursor-pointer flex flex-col items-end min-w-max" @click="toggle">
        <span class="">
            <p class="inline mr-2 uppercase">{{ title }}</p>
            <img class="inline w-[10px] md:w-[13px]" :class="{ 'rotate-180': active }" :src="arrowPath" alt="">
        </span>

        <div v-show="active" class="py-2 mt-2 bg-white rounded-md shadow-xl absolute top-10">
            <a v-for="item in items" class="ml-2 mr-2 block px-4 py-2 text-sm hover:underline text-black uppercase no-underline" :href="item.link">
                <p>{{ item.text }}</p>
            </a>
        </div>
    </span>
</template>

<script>
export default {

    props: {
        title: String,
        items: {
            type: Array,
            validator: function(array) {
                return array.every((obj) => typeof obj.text !== 'undefined' && typeof obj.link !== 'undefined')
            }
        }
    },
    data() {
        return {
            arrowPath: '/img/arrow.svg',
            active: false
        }
    },
    methods: {
        toggle() {
            this.active = !this.active
        }
    }
}
</script>